import React from 'react'

export const IconCaretDown = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M8 10L10.5 12.1429L13 10" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" />
  </svg>
)

export const IconCopy = (props) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
    <path
      d="M1 4C1 3.58579 1.33579 3.25 1.75 3.25H7C7.41421 3.25 7.75 3.58579 7.75 4V9.25C7.75 9.66421 7.41421 10 7 10H1.75C1.33579 10 1 9.66421 1 9.25V4Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path d="M2.5 1.00003L8.49999 1.00001C9.32842 1 10 1.67158 10 2.50001V8.5" stroke="white" strokeWidth="1.5" />
  </svg>
)

export const IconChatBubble = (props) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" {...props}>
      <path
        d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H16C16.8284 0.5 17.5 1.17157 17.5 2V10C17.5 10.8284 16.8284 11.5 16 11.5H15.0034C13.654 11.5 12.6481 12.7442 12.9309 14.0637C13.1605 15.1352 11.8733 15.8644 11.0722 15.1168L7.91747 12.1724C7.45454 11.7403 6.8449 11.5 6.21167 11.5H2C1.17157 11.5 0.5 10.8284 0.5 10V2Z"
        stroke="#3C92DC"
      />
      <path
        d="M2 3C2 2.44772 2.44772 2 3 2H15C15.5523 2 16 2.44772 16 3V9C16 9.55228 15.5523 10 15 10H13C12.4477 10 12 10.4477 12 11C12 11.824 11.0592 12.2944 10.4 11.8L8.26667 10.2C8.09357 10.0702 7.88304 10 7.66667 10H3C2.44772 10 2 9.55228 2 9V3Z"
        fill="#ECF5FD"
      />
      <circle cx="5" cy="6" r="1" fill="#3C92DC" />
      <circle cx="9" cy="6" r="1" fill="#3C92DC" />
      <circle cx="13" cy="6" r="1" fill="#3C92DC" />
    </svg>
  )
}

export const IconRightArrow = (props) => {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" {...props}>
      <path d="M3 8H10M10 8L7.52941 5M10 8L7.52941 11" stroke="currentColor" strokeWidth="1.6" strokeLinecap="square" />
    </svg>
  )
}

export const IconCheck = (props) => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" {...props}>
    <path
      d="M3.25 4.9375L2.78742 5.39414C2.91493 5.52331 3.09064 5.59328 3.27203 5.58713C3.45342 5.58098 3.62398 5.49926 3.74245 5.36176L3.25 4.9375ZM0.599925 3.17824L2.78742 5.39414L3.71258 4.48086L1.52508 2.26495L0.599925 3.17824ZM3.74245 5.36176L7.24245 1.29926L6.25755 0.450739L2.75755 4.51324L3.74245 5.36176Z"
      fill="currentColor"
    />
  </svg>
)

export const IconInfoCircle = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <circle cx="10" cy="10" r="8" fill="var(--washed-blue)" />
    <path
      d="M8.87109 6L9.05859 11.6836H10.9336L11.1211 6H8.87109ZM9.99609 14.6602C10.752 14.6602 11.1973 14.2676 11.1973 13.5938C11.1973 12.9141 10.752 12.5215 9.99609 12.5215C9.24023 12.5215 8.80078 12.9141 8.80078 13.5938C8.80078 14.2676 9.24023 14.6602 9.99609 14.6602Z"
      fill="var(--blue)"
    />
  </svg>
)

export const IconBlueCheckCircleLight = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8" cy="8" r="8" fill="var(--washed-blue)" />
    <path d="M4 8.5L7 11L12 5" stroke="var(--blue)" strokeWidth="2" />
  </svg>
)
