import React, { useState } from "react"
import { Button } from 'antd'
import { IconCheck, IconInfoCircle } from '../../icons'
import { getPlanDetails } from '../../utils/constants'
import classNames from 'classnames'
import moment from 'moment'
import { bookACallHref } from '../../OnPremSetup'
import Tooltip from '../../utils/Tooltip'
import { DifferentiatedPricingConfirmationModal } from '../DifferentiatedPricingConfirmationModal'

import '../../common.scss'
import '../../OnPremSetup.scss'
import '../../Setup.scss'
import '../Billing.scss'
import '../../utils/Button.scss'
import SupportAssistedPlanChangeModal from "../SupportAssistedPlanChangeModal";
import { AnnualToMonthlyChangeModal } from "../AnnualToMonthlyChangeModal";

const className = 'billing__plans'
const standardUserTooltip = 'Users that make edits to apps or workflows are billed as standard users'
const endUserTooltip = 'Enabled users that don’t make edits to apps or workflows are billed as end users'

export const PlanHeaders = (props) => {
  const {
    inBillingCompliance,
    currentPlanName,
    onCTAClick,
    trialExpiring,
    subscriptionStatus,
    ssopUserEmail,
    useLegacyFreePlan,
    numUsers,
    billingPeriod,
    upgradeRequired,
    subscribedBillingPeriod,
    billingType,
    billingStartedAt,
  } = props

  let isSubscriptionActive = ['active', 'unpaid', 'trialing', 'past_due'].includes(subscriptionStatus)
  const disableDowngrade = numUsers > 5 && !useLegacyFreePlan
  const isOnDifferentiated = billingType === 'differentiated'
  const annualBillingSelected = billingPeriod === 'annual'
  const isOnAnnual = subscribedBillingPeriod === 'annual'
  const annualSubscriptionEndDate = new Date(billingStartedAt * 1000)
  annualSubscriptionEndDate.setFullYear(annualSubscriptionEndDate.getFullYear() + 1);
  const annualChangeEligible = isOnAnnual && moment().diff(annualSubscriptionEndDate, 'days') >= -30

  const billingTypeMatch = (billingPeriod === 'monthly' && !isOnAnnual) || (billingPeriod === 'annual' && isOnAnnual)
  const getPricingCopy = (pd) => {
    if (pd.planName === 'Freetool') {
      return <>
        <div style={{ fontSize: 14, fontWeight: 600, color: '#222' }}>{`$${pd.price}`}</div>
        <div>up to 5 users</div>
      </>
    } else if (pd.planName === 'Free') {
      return '$0/user/month'
    } else if (pd.planName === 'Enterprise') {
      return 'Custom pricing'
    } else {
      let standardUserPrice = pd.monthlyStandardUserPrice
      let endUserPrice = pd.monthlyEndUserPrice
      if (annualBillingSelected) {
        standardUserPrice = pd.annualStandardUserPrice
        endUserPrice = pd.annualEndUserPrice
      }
      return <>
        <div style={{ fontSize: 14, fontWeight: 600, color: '#222' }}>{`$${standardUserPrice}/mo`}</div>
        <div>per <Tooltip title={standardUserTooltip}><span style={{ borderBottom: '1px dashed var(--washed-gray)' }}>standard user</span></Tooltip>
        </div>
        <div style={{ fontSize: 14, fontWeight: 600, color: '#222', marginTop: 10 }}>{`$${endUserPrice}/mo`}</div>
        <div>per <Tooltip title={endUserTooltip}><span
          style={{ borderBottom: '1px dashed var(--washed-gray)' }}>end user</span></Tooltip>
        </div>
      </>
    }
  }

  const getSubscribeButton = (planName, planDisplayName, setShowUserSelectionModal) => {
    if (planName === 'enterprise') {
      return (
        <Button target="_blank" type="primary" href={bookACallHref(ssopUserEmail)} className="retool-button">
          Book a call
        </Button>
      )
    }

    let [showSupportUpgradeModal, setShowSupportUpgradeModal] = useState(false)
    let [showAnnualChangeModal, setShowAnnualChangeModal] = useState(false)

    const disabledFreetool = disableDowngrade && planName === 'freetool'
    const disabledPaidPlan = upgradeRequired && planName !== 'freetool'
    const isDowngrade = (currentPlanName === 'pro' && planName === 'team') || planName === 'freetool'
    const disabledReupgrade = currentPlanName === 'freetool' && !inBillingCompliance

    const disabledOnAnnual = (isOnAnnual && (isDowngrade || billingPeriod === 'monthly')) && !annualChangeEligible
    const disableButton = disabledFreetool || disabledPaidPlan || disabledOnAnnual || disabledReupgrade

    const isSamePlanDifferentBillingPeriod =
      isOnDifferentiated && currentPlanName === planName && !billingTypeMatch
    let buttonText
    if (annualChangeEligible) {
      if (planName === 'freetool') {
        buttonText = 'Downgrade for next year'
      } else if (billingTypeMatch) {
        buttonText = 'Renew for next year'
      } else {
        buttonText = 'Subscribe for next year'
      }
    } else {
      buttonText = isSamePlanDifferentBillingPeriod ? 'Subscribe' : isDowngrade ? 'Downgrade' : 'Upgrade'
    }

    if (planName === currentPlanName && isSubscriptionActive &&
      ((billingTypeMatch && isOnDifferentiated) || planName === 'freetool' || planName === 'free') && !annualChangeEligible) {
      return (
        <Button className="retool-button current-plan">
          <IconCheck className="check-circle blue"/>
          Current plan
        </Button>
      )
    }

    let tooltipTitle
    if (disabledPaidPlan) {
      tooltipTitle = 'Update Retool to take advantage of new pricing.'
    } else if (disabledFreetool) {
      tooltipTitle = 'To use Retool for free, select up to 5 users to enable on the Users page within your instance.'
    } else if (disabledOnAnnual) {
      tooltipTitle = `You’re currently on an annual plan that runs through ${moment(annualSubscriptionEndDate).format('MMM DD, YYYY')}. 
      To switch to a different plan at the end of this billing cycle, email support@retool.com`
    } else if (disabledReupgrade) {
      tooltipTitle = 'You currently have outstanding invoices and so are unable to begin a new subscription. Please visit the Billing portal to view and fulfill your outstanding invoices'
    }
    return (
      <>
        <SupportAssistedPlanChangeModal showModal={showSupportUpgradeModal} setShowModal={setShowSupportUpgradeModal}/>
        <AnnualToMonthlyChangeModal
          showModal={showAnnualChangeModal}
          setShowModal={setShowAnnualChangeModal}
          billingPeriodEndDate={moment(annualSubscriptionEndDate).format('MMM DD, YYYY')}
          planName={planDisplayName}
        />
        <Tooltip
          title={tooltipTitle}
        >
          <Button
            className="retool-button"
            type="primary"
            onClick={() => {
              if (isOnAnnual) {
                if (annualChangeEligible) {
                  if (planName === 'freetool' || billingPeriod === 'monthly') {
                    setShowAnnualChangeModal(true)
                  } else {
                    setShowUserSelectionModal(true)
                  }
                } else if (!isDowngrade) {
                  setShowSupportUpgradeModal(true)
                }
              } else if (planName === 'freetool' || (isOnDifferentiated && !annualBillingSelected)) {
                onCTAClick(planName.toLowerCase())
              } else {
                setShowUserSelectionModal(true)
              }
            }}
            disabled={disableButton}
          >
            {buttonText}
            {disableButton && <IconInfoCircle/>}
          </Button>
        </Tooltip>
      </>
    )
  }

  return (
    <>
      {getPlanDetails(useLegacyFreePlan).map((pd, i) => {
        let [showUserSelectionModal, setShowUserSelectionModal] = useState(false)
        return (
          <div
            className={classNames(`${className}__plan-header`, {
              'selected-plan': pd.planName.toLowerCase() === currentPlanName,
            })}
            data-testid={pd.planName}
            id={`plan_header_${i + 1}`}
            key={i}
          >
            <div className={`${className}__plan-header__plan-name`}> {pd.displayName} </div>
            <div className={`${className}__plan-header__price`}>
              {getPricingCopy(pd)}
            </div>
            <div style={{ padding: '16px 16px 8px 16px', textAlign: 'center' }}>
              <DifferentiatedPricingConfirmationModal
                billingPeriod={billingPeriod}
                planName={pd.displayName}
                numUsers={numUsers}
                currencySymbol='$'
                standardUserCost={annualBillingSelected ? pd.annualStandardUserPrice : pd.monthlyStandardUserPrice}
                endUserCost={annualBillingSelected ? pd.annualEndUserPrice : pd.monthlyEndUserPrice}
                firstBillingDate={annualChangeEligible ? moment(annualSubscriptionEndDate).format('MMM DD, YYYY') : moment().add(1, 'months').format('MMM DD, YYYY')}
                visible={showUserSelectionModal}
                onCancel={() => setShowUserSelectionModal(false)}
                renewal={annualChangeEligible}
              />
              {getSubscribeButton(pd.planName.toLowerCase(), pd.displayName, setShowUserSelectionModal)}
              {pd.planName.toLowerCase() === 'enterprise' && pd.planName.toLowerCase() === currentPlanName && trialExpiring && (
                <div className="light-blue fw-400">{`Trial ending ${moment(trialExpiring).fromNow()}`}</div>
              )}
              {(pd.planName.toLowerCase() === 'team' || pd.planName.toLowerCase() === 'pro') && (
                <div style={{
                  fontSize: 11,
                  color: '#777',
                  paddingTop: 5
                }}>{annualBillingSelected ? 'billed annually' : 'billed monthly'}</div>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
