import React from 'react'
import { IconCheck } from '../../icons'
import { getPlanDetails } from '../../utils/constants'
import classNames from 'classnames'

import '../../common.scss'
import '../../OnPremSetup.scss'
import '../../Setup.scss'
import '../Billing.scss'
import '../../utils/Button.scss'

const className = 'billing__plans'

export const PlanDetails = (props) => {
  const { sectionTitle, currentPlanName, useLegacyFreePlan } = props

  return (
    <>
      {getPlanDetails(useLegacyFreePlan).map((pd, i) => (
        <div
          key={i}
          className={classNames(`plan_detail_${pd.planName}`, 'feature-detail', {
            'selected-plan': pd.planName.toLowerCase() === currentPlanName,
          })}
        >
          {pd.features[sectionTitle].map((f, i) => (
            <React.Fragment key={i}>
              {/*Show the user limit for SSOP and on prem billing portal*/}
              <div
                className={`${className}__table-cell feature_${i + 1} ${i === 0 ? 'section-title' : 'checkbox-cell'}`}>
                {f ? f === true ? <IconCheck className="check-circle"/> : f : ''}
              </div>
            </React.Fragment>
          ))}
        </div>
      ))}
    </>
  )
}
