import React from "react"
import PropTypes from "prop-types"
import OnPremSetupPage from "./OnPremSetup"
import TopBar from "./TopBar"
import TrialBanner from "./utils/TrialBanner"
import HelpBubble from "./utils/HelpBubble"
class SsopPortal extends React.Component {
  render () {
    return (
      <React.Fragment>
        <TrialBanner 
          subscriptionStatus={this.props.subscriptionStatus}
          trialExpiring={this.props.trialExpiring}
        />
        <TopBar userEmail={this.props.ssopUserEmail} userName={this.props.ssopUserName} userImage={this.props.ssopUserImage}></TopBar>
        <OnPremSetupPage email={this.props.ssopUserEmail} licenseKey={this.props.licenseKey}></OnPremSetupPage>
        <HelpBubble/>
      </React.Fragment>
    );
  }
}

SsopPortal.propTypes = {
  licenseKey: PropTypes.string,
  ssopUserEmail: PropTypes.string,
  ssopUserName: PropTypes.string,
  ssopUserImage: PropTypes.string,
  subscriptionStatus: PropTypes.string,
  trialExpiring: PropTypes.string
};

export default SsopPortal
