import React from "react"
import { Button } from 'antd'

import { IconInfoCircle, IconBlueCheckCircleLight } from "../icons"

const BannerHeader = (props) => {
  if (props.upgradeRequired) {
    return (
      <div style={{ fontWeight: 600, fontSize: 14, color: '#222' }}>Update Retool to take advantage of new pricing</div>
    )
  }
  if (props.onFreePlan) {
    return (
      <div style={{ fontWeight: 600, fontSize: 14, color: '#222' }}>Retool pricing is based on user type</div>
    )
  }
  if (props.onDifferentiatedPricingPlan) {
    return (
      <div style={{ fontWeight: 600, fontSize: 14, color: '#222' }}>Pricing plan updated</div>
    )
  }
  return (
    <div style={{ fontWeight: 600, fontSize: 14, color: '#222' }}>Retool is introducing new pricing</div>
  )
}

const PricingDescription = (props) => {
  const businessPlanUpsell = (
    <span>We're also offering a new <strong>Business</strong> plan which enables added functionality for growing teams.
      &nbsp;<a href="https://docs.retool.com/docs/organization-billing-usage">Learn more</a></span>
  )
  if (props.onFreePlan) {
    return (<div style={{ fontSize: 12, color: '#555', marginTop: 3 }}>
      With Retool paid plans, you pay different rates for different user types: <strong>standard users</strong>{' '}
      who build and edit in Retool, and <strong>end users</strong> who only use apps. {businessPlanUpsell}
    </div>)
  }
  if (props.onDifferentiatedPricingPlan) {
    return (<>
      <div style={{ fontSize: 12, color: '#555', marginTop: 3 }}>
        You’ve successfully subscribed to the Retool {props.planDisplayName} plan. Pricing will be based on the number
        of standard users and end users that are enabled on Retool each month.
      </div>
      <div style={{ fontSize: 12, color: '#555', marginTop: 13 }}>
        Need help with new pricing? <a href="mailto:support@retool.com">Contact support</a>
      </div>
    </>)
  }
  return (
    <div style={{ fontSize: 12, color: '#555', marginTop: 3 }}>
      With our new pricing plans, you pay different rates for different user types:{' '}
      <strong>standard users</strong> who build and edit in Retool, and <strong>end users</strong> who only use
      apps. {businessPlanUpsell}
    </div>
  )
}

const CurrentPlanDescription = (props) => {
  return (
    <>
      <div style={{ fontWeight: 500, fontSize: 12, color: '#222', marginTop: 8 }}>
        Current plan: {props.planDisplayName} (${props.legacyUserCost} per user/month x {props.numUsers}{' '}
        users)
      </div>
      <div style={{ fontSize: 11, color: '#777', marginTop: 3 }}>
        Your organization can remain on this legacy pricing plan until December 31, 2024 or you can select a new
        plan below
      </div>
    </>
  )
}

const UpgradeCTA = (props) => {
  return (
    <>
      <div style={{ fontSize: 12, color: '#555', marginTop: 8 }}>
        You're currently on Retool version {props.currentVersion}. Upgrade to 2.117 to enable new pricing on this
        page.
      </div>
      <div style={{ marginTop: 8 }}>
        <Button
          target="_blank"
          type="primary"
          href="https://docs.retool.com/docs/updating-self-hosted-retool"
          className="retool-button"
        >
          Learn how to update Retool
        </Button>
      </div>
    </>
  )
}

const DifferentiatedPricingBanner = (props) => {
  const onFreePlan = props.stripePlanName === 'free' || props.stripePlanName === 'freetool'
  const onDifferentiatedPricingPlan = props.billingType === 'differentiated'
  let planDisplayName = 'Free'
  let legacyUserCost
  if (props.stripePlanName === 'team') {
    planDisplayName = 'Team'
    legacyUserCost = '10'
  } else if (props.stripePlanName === 'pro') {
    planDisplayName = 'Business'
    legacyUserCost = '50'
  }

  return (
    <div
      style={{
        background: '#F5FAFF',
        border: '1px solid #D8ECFD',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        padding: 20,
        margin: '10px 20px 20px 20px',
      }}
    >
      {onDifferentiatedPricingPlan && (<IconBlueCheckCircleLight style={{ width: 20, height: 20, marginRight: 10 }}
      />)}
      {!onDifferentiatedPricingPlan && (<IconInfoCircle style={{ width: 20, height: 20, marginRight: 10 }}
      />)}
      <div style={{ maxWidth: 620 }}>
        <BannerHeader
          upgradeRequired={props.upgradeRequired}
          onFreePlan={onFreePlan}
          onDifferentiatedPricingPlan={onDifferentiatedPricingPlan}
        />
        <PricingDescription
          onFreePlan={onFreePlan}
          onDifferentiatedPricingPlan={onDifferentiatedPricingPlan}
          planDisplayName={planDisplayName}
        />
        {!onFreePlan && !onDifferentiatedPricingPlan && (
          <CurrentPlanDescription
            planDisplayName={planDisplayName}
            legacyUserCost={legacyUserCost}
            numUsers={props.numUsers}
          />
        )}
        {props.upgradeRequired && (<UpgradeCTA currentVersion={props.currentVersionNumber}/>)}
      </div>
    </div>
  )
}

export default DifferentiatedPricingBanner
