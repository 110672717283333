import React, { useState } from "react"
import ls from 'local-storage'
import '../common.scss'
import './TrialBanner.scss'
import classnames from 'classnames'
import moment from "moment"
import { CloseOutlined } from '@ant-design/icons'
import { Button } from "antd"

const ONE_DAY = 1000 * 60 * 60 * 24

const TrialBanner = (props) => {
    let [lastDismissTime, setLastDismissTime] = useState(ls.get('trialBannerLastDismissTime'))

    const onDismiss = () => {
        const lastDismissTime = new Date().getTime()
        setLastDismissTime(lastDismissTime)
        ls.set('trialBannerLastDismissTime', lastDismissTime)
    }
    
    // if trialexpiring prop is not defined, it doesn't exist on DB which means this is not a trial user.
    let isDismissed = lastDismissTime && new Date().getTime() - parseInt(lastDismissTime, 10) < ONE_DAY
    let isTrialExpired = props.trialExpiring ? moment(props.trialExpiring) < moment() : false 
    let isSubscriptionActive = props.subscriptionStatus === 'active'
    let isSubscriptionTrial = props.subscriptionStatus === 'trialing'
    let isSubscriptionCanceled = props.subscriptionStatus === 'canceled'
    let showTrialBanner = !isDismissed && !isSubscriptionActive && (isSubscriptionTrial || isTrialExpired || isSubscriptionCanceled)
    
    const upgradeCTAText = isTrialExpired || isSubscriptionTrial ?
                            'Choose a plan to continue' : 'Upgrade now'
    const bannerMessage = isTrialExpired ?
                            'Your trial has expired. Your plan has been automatically downgraded to free.' :
                            isSubscriptionTrial ?
                                `Your trial is ending ${moment(props.trialExpiring).fromNow()}` :
                                'Your plan has ended'

    return (
        showTrialBanner && 
        <div className={classnames("trial-banner-container", "flex", "fd-row", {"warning": isTrialExpired || isSubscriptionCanceled})}>
            {bannerMessage}
            <Button 
                className="retool-button"
                type="link"
                href="/billing"
            >
                {upgradeCTAText}
            </Button>
            <CloseOutlined 
                style={{
                    position: 'absolute',
                    right: 20,
                    cursor: 'pointer',
                }}
                onClick={onDismiss}
            />
        </div>
    )
}

export default TrialBanner