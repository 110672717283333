import React from 'react'
import PropTypes from 'prop-types'
import { IconCopy } from './icons'
import { Typography, Button } from 'antd'
import { copyValueToClipboard } from './utils/copyToClipboard'
const { Title } = Typography

import './utils/Button.scss'
import './billing/Billing.scss'
import './common.scss'

class LicenseKey extends React.Component {
  render() {
    return <LicenseKeyDisplay licenseKey={this.props.licenseKey}></LicenseKeyDisplay>
  }
}

export const LicenseKeyDisplay = (props) => {
  return (
    <div className="license-key-container">
      <Typography>
        <Title level={3}>Your license key</Title>
      </Typography>

      <div className="license-key-display">
        <div className="license-key-text"> {props.licenseKey} </div>
        <Button
          className="retool-button"
          type="primary"
          icon={<IconCopy className="mr4" />}
          onClick={() => {
            copyValueToClipboard(props.licenseKey)
          }}
        >
          Copy
        </Button>
      </div>
    </div>
  )
}

LicenseKey.propTypes = {
  licenseKey: PropTypes.string,
}
export default LicenseKey
