import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Typography, Alert, Button } from 'antd';
import DifferentiatedPricingBanner from "./DifferentiatedPricingBanner";
import LicenseKey from '../LicenseKey'
import { PlanHeaders } from './PlanComponents/PlanHeaders'
import { PlanFeatureNames } from './PlanComponents/PlanFeatureNames'
import { PlanDetails } from './PlanComponents/PlanDetails'

const { Title } = Typography;
import classNames from 'classnames'

import { OnPremSetupSidebar } from '../OnPremSetup'

import '../common.scss'
import '../OnPremSetup.scss'
import '../Setup.scss'
import './Billing.scss'
import '../utils/Button.scss'
import '../utils/Modal.scss'
import { Segmented } from "antd";
import { getPlanFeaturesCategories } from "../utils/constants";

const SsopBilling = (props) => {
  let [stripePlanId, setStripePlanId] = useState(props.stripePlanId)
  let [stripePlanName, setStripePlanName] = useState(props.stripePlanName)
  let [subscriptionStatus, setSubscriptionStatus] = useState(props.subscriptionStatus)
  let [trialExpiring, setTrialExpiring] = useState(props.trialExpiring)
  const [inBillingCompliance, setIsInBillingCompliance] = useState(true)

  const didClickCTA = async (planName) => {
    updateStripeSubscription(planName)
  }

  const className = 'billing__plans'

  useEffect(() => {
    const token = document.querySelector('meta[name="csrf-token"]').content
    const callAPI = async () => {
      const response = await fetch('/api/v1/check_billing_compliance', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
      })

      setIsInBillingCompliance((await response.json()).isInBillingCompliance)
    }

    // We only need to check if an org is in compliance when they are downgraded to Freetool
    // and are attempting to subscribe to a new plan
    if (stripePlanName === 'freetool') {
      callAPI()
    }
  }, [stripePlanName])

  const updateStripeSubscription = async (planName) => {
    const REDIRECT_PATH = 'billing'
    let token = document.querySelector('meta[name="csrf-token"]').content;
    return fetch('/api/v1/update_stripe_subscription', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        plan_name: planName,
        redirect_url: `${window.location.origin}/${REDIRECT_PATH}`,
      })
    }).then(result => {
      return result.json()
    }).then(data => {
      if (data.checkout_required) {
        window.location.href = data.session_url
      } else {
        setStripePlanId(data?.price_id ?? stripePlanId)
        setStripePlanName(data?.plan_name ?? stripePlanName)
        setSubscriptionStatus(data?.subscription_status ?? subscriptionStatus)
        setTrialExpiring(data?.trial_expiring ?? trialExpiring)
      }
    })
  }

  const createStripeCustomerPortalSession = async () => {
    const REDIRECT_PATH = 'billing'
    let token = document.querySelector('meta[name="csrf-token"]').content;
    return fetch('/api/v1/create_stripe_customer_portal', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        redirect_url: `${window.location.origin}/${REDIRECT_PATH}`
      })
    }).then(result => {
      return result.json()
    }).then(data => {
      if (data.stripe_customer_portal_url) {
        window.location.href = data.stripe_customer_portal_url
      }
    })
  }
  const useLegacyFreePlan = stripePlanName === 'free'
  const [billingPeriod, setBillingPeriod] = useState('Annual')

  return (
    <div className="onprem-container flex fd-row">
      <OnPremSetupSidebar email={props.ssopUserEmail}></OnPremSetupSidebar>
      <div className="onprem-setup-content">
        <div>
          <LicenseKey licenseKey={props.licenseKey}></LicenseKey>
          <DifferentiatedPricingBanner {...props} stripePlanName={stripePlanName}/>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className="billing-header">
              <Title level={4}>Billing and plans</Title>
            </Typography>
            <div>
              <div style={{ display: 'inline-block', fontSize: 12, color: '#999' }}>Billing</div>
              <Segmented style={{ fontSize: 12, margin: '0px 20px 0px 20px' }} className="billing-header"
                         options={['Annual', 'Monthly']} value={billingPeriod}
                         onChange={setBillingPeriod}/>
            </div>

          </div>

          <div className="settings-header">
            <div className="billing__plans">
              <div className={`${className}__table`}>
                <div className={classNames(`${className}__table-row`, { [`${className}__four`]: true })}>
                  <div/>
                  <PlanHeaders
                    inBillingCompliance={inBillingCompliance}
                    currentPlanName={stripePlanName}
                    onCTAClick={didClickCTA}
                    trialExpiring={subscriptionStatus === 'trialing' ? trialExpiring : null}
                    subscriptionStatus={subscriptionStatus}
                    ssopUserEmail={props.ssopUserEmail}
                    useLegacyFreePlan={useLegacyFreePlan}
                    numUsers={props.numUsers}
                    billingPeriod={billingPeriod.toLowerCase()}
                    upgradeRequired={props.upgradeRequired}
                    billingType={props.billingType}
                    subscribedBillingPeriod={props.subscribedBillingPeriod}
                    billingStartedAt={props.billingStartedAt}
                  />
                </div>
                {getPlanFeaturesCategories().map((sectionTitle, i) => (
                  <div className={classNames(`${className}__table-row`, { [`${className}__four`]: true })}
                       id={`section_${sectionTitle}`} key={i}>
                    <PlanFeatureNames sectionTitle={sectionTitle}/>
                    <PlanDetails
                      sectionTitle={sectionTitle}
                      currentPlanName={stripePlanName}
                      useLegacyFreePlan={useLegacyFreePlan}
                    />
                  </div>
                ))}
              </div>
            </div>
            <span style={{ display: 'flex', marginTop: 8, justifyContent: 'flex-end', fontSize: 12 }}>
              *additional runs are available at $75 per 5000 runs per month
            </span>
          </div>

          <Typography className="billing-header">
            <Title level={3}>Billing Details</Title>
          </Typography>

          <div className="settings-header">
            <Alert
              className='manage-billing-alert'
              type="info"
              message={
                <div className={`manage-billing-alert__content`}>
                  <div>
                    <div className={`manage-billing-alert__title`}>Use our billing portal to manage your account.</div>
                    View invoices, update payment methods and change your billing email.
                  </div>
                  <Button className="retool-button" type="primary" onClick={createStripeCustomerPortalSession}>
                    Go to portal
                  </Button>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

SsopBilling.propTypes = {
  stripeSubscriptionId: PropTypes.string,
  stripePlanId: PropTypes.string,
  stripePlanName: PropTypes.string,
  stripeCustomerId: PropTypes.string,
  subscriptionStatus: PropTypes.string,
  trialExpiring: PropTypes.string,
  ssopUserEmail: PropTypes.string,
  licenseKey: PropTypes.string,
  numUsers: PropTypes.number,
  upgradeRequired: PropTypes.bool,
  currentVersionNumber: PropTypes.string,
  billingType: PropTypes.string,
  subscribedBillingPeriod: PropTypes.string,
  billingStartedAt: PropTypes.number,
};

export default SsopBilling
