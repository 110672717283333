import React from 'react'

export const IconChatBubble = (props) => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" {...props}>
            <path
                d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H16C16.8284 0.5 17.5 1.17157 17.5 2V10C17.5 10.8284 16.8284 11.5 16 11.5H15.0034C13.654 11.5 12.6481 12.7442 12.9309 14.0637C13.1605 15.1352 11.8733 15.8644 11.0722 15.1168L7.91747 12.1724C7.45454 11.7403 6.8449 11.5 6.21167 11.5H2C1.17157 11.5 0.5 10.8284 0.5 10V2Z"
                stroke="#3C92DC"
            />
            <path
                d="M2 3C2 2.44772 2.44772 2 3 2H15C15.5523 2 16 2.44772 16 3V9C16 9.55228 15.5523 10 15 10H13C12.4477 10 12 10.4477 12 11C12 11.824 11.0592 12.2944 10.4 11.8L8.26667 10.2C8.09357 10.0702 7.88304 10 7.66667 10H3C2.44772 10 2 9.55228 2 9V3Z"
                fill="#ECF5FD"
            />
            <circle cx="5" cy="6" r="1" fill="#3C92DC" />
            <circle cx="9" cy="6" r="1" fill="#3C92DC" />
            <circle cx="13" cy="6" r="1" fill="#3C92DC" />
        </svg>
    )
}
