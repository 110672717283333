import { message } from 'antd'

export let copyValueToClipboard = (value) => {
    if (value == null) {
        return
    }
    const activeElement = document.activeElement
    const node = document.createElement('textarea')
    node.value = value
    document.body.appendChild(node)
    node.select()
    document.execCommand('copy')
    node.remove()
    activeElement?.focus({ preventScroll: true })
    message.success('Copied to clipboard')
}