import React from 'react'

export const IconHelpCommunity = (props) => (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10.75" cy="5.5" r="1.875" stroke="currentColor" strokeWidth="1.25" />
      <path
        d="M7.25002 13C7.25002 12 7.62695 10 9.13464 10H12.3654C12.9936 10 14.25 10.6 14.25 13"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <circle cx="4.07498" cy="3.5" r="1.875" stroke="currentColor" strokeWidth="1.25" />
      <path
        d="M0.625 11C0.625 10 1.00192 8 2.50961 8H5.125C5.41458 8 6.5 8 7 9"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    </svg>
  )