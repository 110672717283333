import React from "react"
import PropTypes from "prop-types"
import SsopBilling from "./SsopBilling";
import TopBar from "../TopBar";
import TrialBanner from "../utils/TrialBanner"
import HelpBubble from "../utils/HelpBubble"

class SsopBillingContainer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TrialBanner
          subscriptionStatus={this.props.subscriptionStatus}
          trialExpiring={this.props.trialExpiring}
        />
        <TopBar userEmail={this.props.ssopUserEmail} userName={this.props.ssopUserName}
                userImage={this.props.ssopUserImage}></TopBar>
        <SsopBilling
          {...this.props}
        />
        <HelpBubble/>
      </React.Fragment>
    );
  }
}

SsopBillingContainer.propTypes = {
  stripeSubscriptionId: PropTypes.string,
  stripePlanId: PropTypes.string,
  stripePlanName: PropTypes.string,
  stripeCustomerId: PropTypes.string,
  stripePaymentMethod: PropTypes.string,
  subscriptionStatus: PropTypes.string,
  ssopUserEmail: PropTypes.string,
  ssopUserName: PropTypes.string,
  ssopUserImage: PropTypes.string,
  trialExpiring: PropTypes.string,
  numUsers: PropTypes.string,
  upgradeRequired: PropTypes.bool,
  currentVersionNumber: PropTypes.string,
  billingType: PropTypes.string,
  subscribedBillingPeriod: PropTypes.string,
};

export default SsopBillingContainer
