import React from 'react'

export const IconHelpDocs = (props) => (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 4.25H5.24998V12.25H12.75V4.25ZM3.99998 3V13.5H14V3H3.99998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.999985 0H9.62498V1.25H2.24998V10.625H0.999985V0Z"
        fill="currentColor"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 7.5H6.99998V6.5H11V7.5Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 9.5H6.99998V8.5H11V9.5Z" fill="currentColor" />
    </svg>
  )