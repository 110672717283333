import React from 'react'
import Tooltip from '../../utils/Tooltip'

import '../../common.scss'
import '../../OnPremSetup.scss'
import '../../Setup.scss'
import '../Billing.scss'
import '../../utils/Button.scss'
import { getPlanFeatures } from '../../utils/constants'

const className = 'billing__plans'

export const PlanFeatureNames = (props) => {
  const { sectionTitle } = props

  return (
    <div className={`${className}__plan-section`}>
      <div className={`feature-details-container`}>
        <div className={`${className}__table-cell section-title`}>
          {sectionTitle}
        </div>
        {getPlanFeatures()[sectionTitle].map((p, i) => (
          <div
            key={i}
            className={`feature_${i + 1} ${className}__table-cell feature-detail
                        ${p.tooltip !== '' ? 'has-tooltip' : ''} ${
              sectionTitle === 'Apps' && i === 0 ? 'visible-item' : ''
            }`}
          >
            <Tooltip title={p.tooltip !== '' ? p.tooltip : null} placement="topLeft">
              {p.label}
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  )
}
