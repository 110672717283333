// Copied from design-system/Redio in retool_development
import React from 'react'
import { Radio as AntRadio } from 'antd'
import classNames from 'classnames'
import 'antd/lib/radio/style/index'
import './Radio.scss'

const RadioGroup = (props) => (
  <AntRadio.Group {...props} className={classNames('retool-radio-group', props.className)}>
    {props.children}
  </AntRadio.Group>
)

const RadioButton = (props) => (
  <AntRadio.Button {...props} className={classNames('retool-radio-button', props.className)}>
    {props.children}
  </AntRadio.Button>
)

const Radio = (props) => (
  <AntRadio className={classNames('retool-radio', props.className)} style={props.style} {...props}>
    {props.children}
  </AntRadio>
)

Radio.Group = RadioGroup
Radio.Button = RadioButton

export default Radio
