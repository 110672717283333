import React, { useState } from 'react'
import { Button, Modal, message } from 'antd';

export const AnnualToMonthlyChangeModal = (props) => {
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setLoading(true)
    let token = document.querySelector('meta[name="csrf-token"]').content;
    fetch('/api/v1/update_subscription_schedule', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        plan_name: props.planName,
        cancelled: true,
        builder_count: 0,
        end_user_count: 0,
      })
    }).then(result => {
      if (result.ok) {
        message.success('Subscription updated successfully')
      } else {
        message.error('Error scheduling subscription update, please try again later.')
      }
      setLoading(false)
      props.setShowModal(false)
    })
  };

  const handleCancel = () => {
    props.setShowModal(false)
  };

  return (
    <Modal
      visible={props.showModal}
      title={props.planName === 'free' ? 'Switch to Free' : 'Switch to monthly'}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Confirm"
      width={480}
      bodyStyle={{ borderRadius: 8 }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          Confirm
        </Button>,
      ]}
    >
      <p>
        {props.planName === 'free'
          ? `At the end of your current subscription term on ${props.billingPeriodEndDate}, you will switch to the
      ${props.planName} plan.`
          : `At the end of your current subscription term on ${props.billingPeriodEndDate}, you will switch to the
      ${props.planName} plan at the monthly rate.`}
      </p>
    </Modal>
  )
}
