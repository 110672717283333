import React, { useState } from 'react'

import { Button, Modal, InputNumber, Tooltip, message } from 'antd'
import classNames from 'classnames'

const SSOP_MAX_USERS = 100

export const DifferentiatedPricingConfirmationModal = (props) => {
  const annualBillingPeriod = props.billingPeriod === 'annual'
  const minUsers = annualBillingPeriod ? 1 : 0
  const isRenewal = props.renewal

  const [standardUserCount, setStandardUserCount] = useState(Math.max(Math.round(props.numUsers / 3), minUsers))
  const [endUserCount, setEndUserCount] = useState(Math.max(Math.round((props.numUsers / 3) * 2), minUsers))
  const [loading, setLoading] = useState(false);

  const createDifferentiatedCheckoutSession = async () => {
    const REDIRECT_PATH = 'billing'
    let token = document.querySelector('meta[name="csrf-token"]').content
    let url = annualBillingPeriod
      ? '/api/v1/create_annual_stripe_checkout_session'
      : '/api/v1/create_monthly_stripe_checkout_session'
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
      body: JSON.stringify({
        plan_name: props.planName,
        redirect_url: `${window.location.origin}/${REDIRECT_PATH}`,
        billing_period: props.billingPeriod,
        is_differentiated: true,
        builder_count: standardUserCount,
        end_user_count: endUserCount,
        currency: 'USD',
      }),
    })

    if (resp.ok) {
      const respBody = await resp.json()
      window.location.href = respBody.session_url
    } else if (resp.status === 400) {
      const respBody = await resp.json()
      message.error(respBody.error.message)
      setLoading(false)
    } else {
      message.error('Unable to create checkout session. Please try again later.')
      setLoading(false)
    }
  }
  const updateSubscriptionSchedule = async () => {
    let token = document.querySelector('meta[name="csrf-token"]').content;
    const resp = await fetch('/api/v1/update_subscription_schedule', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        plan_name: props.planName,
        builder_count: standardUserCount,
        end_user_count: endUserCount,
        cancelled: false,
      })
    })
    if (resp.ok) {
      message.success('Subscription updated successfully')
    } else {
      message.error('Error scheduling subscription update, please try again later.')
    }
    setLoading(false)
    props.onCancel()
  }


  let headerText
  if (isRenewal) {
    headerText = `You can update your renewal until ${props.firstBillingDate}. If you don't update, we'll automatically subscribe you with the same plan and same number of seats for next year. `
  } else if (annualBillingPeriod) {
    headerText =
      'Annual pricing offers a discounted rate for pre-paying seats for each user type. Any additional users will be billed on a monthly basis.'
  } else {
    headerText =
      'Each month you’ll be charged for standard users and end users enabled on Retool. User type (and associated price) is determined automatically based on a user’s Retool activity during the previous month. '
  }

  const handleOk = () => {
    setLoading(true)
    return isRenewal ? updateSubscriptionSchedule() : createDifferentiatedCheckoutSession()
  }

  return (
    <Modal
      className={classNames('retool-modal', 'retool-modal-ds')}
      visible={props.visible}
      title={`${isRenewal ? 'Renew with' : 'Subscribe to'} Retool ${props.planName} (${props.billingPeriod})`}
      okText={isRenewal ? 'Confirm' : "Continue to billing"}
      width={480}
      onOk={handleOk}
      onCancel={props.onCancel}
      footer={[
        <Button key="back" onClick={props.onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          {isRenewal ? 'Confirm' : 'Continue to billing'}
        </Button>,
      ]}
    >
      <div style={{ borderBottom: annualBillingPeriod ? '1px solid lightgrey' : 'none', paddingBottom: 10 }}>
        {headerText}
      </div>

      <div
        style={{
          backgroundColor: annualBillingPeriod ? 'white' : '#F9F9F9',
          border: annualBillingPeriod ? 'none' : '1px solid #DFDFDF',
          borderRadius: 4,
          padding: annualBillingPeriod ? 0 : 16,
        }}
      >
        <div style={{ paddingTop: annualBillingPeriod ? 10 : 0, fontWeight: 500, color: '#222' }}>
          {annualBillingPeriod ? 'Select user types' : 'Price estimator (optional)'}
        </div>
        <div style={{ paddingTop: 5 }}>
          {annualBillingPeriod
            ? `Enter the number of seats you’d like to purchase for each user type. ${props.numUsers ? `Default estimates are based on the
          ${props.numUsers} users currently in your organization.` : ''}`
            : `Use this calculator to estimate your monthly payment. ${props.numUsers ? `Initial numbers have been approximated based on the ${props.numUsers} users currently enabled.` : ''}`}
        </div>
        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: 88 }}>
              <InputNumber
                value={standardUserCount}
                min={minUsers}
                max={SSOP_MAX_USERS}
                onChange={(n) => {
                  n = n ?? 0
                  if (n >= minUsers && n + endUserCount <= SSOP_MAX_USERS) {
                    setStandardUserCount(n)
                  }
                }}
                aria-label="Standard user count"
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <Tooltip title="Users that make edits to apps or workflows are billed as standard users">
                <div
                  style={{
                    fontWeight: 500,
                    color: '#222',
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'dashed',
                    textDecorationColor: 'lightgray',
                  }}
                >
                  Standard user
                </div>
              </Tooltip>
              <div>{`${props.currencySymbol + props.standardUserCost}/month, billed ${
                annualBillingPeriod ? 'annually' : 'monthly'
              }`}</div>
            </div>
          </div>
          <div>{`${props.currencySymbol + standardUserCount * props.standardUserCost}`}</div>
        </div>
        <div style={{ margin: '20px 0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: 88 }}>
              <InputNumber
                value={endUserCount}
                min={minUsers}
                max={SSOP_MAX_USERS}
                onChange={(n) => {
                  n = n ?? 0
                  if (n >= minUsers && n + standardUserCount <= SSOP_MAX_USERS) {
                    setEndUserCount(n)
                  }
                }}
                aria-label="End user count"
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <Tooltip title="Enabled users that don’t make edits to apps or workflows are billed as end users">
                <div
                  style={{
                    fontWeight: 500,
                    color: '#222',
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'dashed',
                    textDecorationColor: 'lightgray',
                  }}
                >
                  End user
                </div>
              </Tooltip>
              <div>{`${props.currencySymbol + props.endUserCost}/month, billed ${
                annualBillingPeriod ? 'annually' : 'monthly'
              }`}</div>
            </div>
          </div>
          <div>{`${props.currencySymbol + endUserCount * props.endUserCost}`}</div>
        </div>
        {!annualBillingPeriod && (
          <div
            style={{
              paddingTop: 20,
              borderTop: '1px solid #DFDFDF',
              fontWeight: 500,
              color: '#222',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>Estimated Monthly Pricing</div>
            <div>{`${
              props.currencySymbol + (standardUserCount * props.standardUserCost + endUserCount * props.endUserCost)
            }`}</div>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          backgroundColor: '#F5FAFF',
          padding: 8,
          borderRadius: 8,
          fontWeight: 500,
          color: '#222',
        }}
      >
        {annualBillingPeriod ? (
          <>
            <div>Subtotal</div>
            <div>{`${
              props.currencySymbol + (standardUserCount * props.standardUserCost + endUserCount * props.endUserCost)
            } (${
              props.currencySymbol +
              12 * (standardUserCount * props.standardUserCost + endUserCount * props.endUserCost)
            }/year)`}</div>
          </>
        ) : (
          <>
            <div>First bill will be on {props.firstBillingDate}</div>
            <div>Due today: {props.currencySymbol}0</div>
          </>
        )}
      </div>
    </Modal>
  )
}
