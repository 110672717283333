import React from "react"
import { Modal } from 'antd';

const SupportAssistedPlanChangeModal = (props) => {
    return (
        <Modal
            visible={props.showModal}
            title="We'd be glad help you make this change"
            onOk={() => {
                window.open('mailto:support@retool.com')
                props.setShowModal(false)
            }}
            onCancel={() => {
                props.setShowModal(false)
            }}
            okText="Email support"
            width={480}
            bodyStyle={{ borderRadius: 8 }}
        >
            <p>
                A member of our customer support team can assist in updating your account. Send an email to{' '}
                <a href="mailto:support@retool.com">support@retool.com</a> and we'll get you set up on a new plan.
            </p>
        </Modal>
    )
}

export default SupportAssistedPlanChangeModal
