import React, { useState } from 'react'
import './Setup.scss'
import './common.scss'
import './OnPremSetup.scss'
import { Typography } from 'antd'
const { Title, Text } = Typography
import { LinkOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import ssopSidebarLogo from './utils/ssopSidebarLogo.svg'
import { IconChatBubble, IconRightArrow } from './icons'
import LicenseKey from './LicenseKey'

const BOOK_A_CALL_HREF_MARKETING_CAMPAIGNS = 'https://retool.chilipiper.com/router/marketing-campaigns'
export const bookACallHref = (email) => {
  return `${BOOK_A_CALL_HREF_MARKETING_CAMPAIGNS}?id=${email}&utm_source=app&utm_medium=product`
}

const HelpLinks = (props) => {
  return (
    <div className="onprem-help-links mb4">
      <div className="fs-13 fw-600 mb8">📙 Need help?</div>
      <div className="strong gray fs-13">Deploying on complex infrastructure?</div>
      <a href={bookACallHref(props.email)} target="_blank">
        Book a call with a Retool engineer
      </a>
      <a
        className="gray link fs-13 flex items-center"
        href="https://docs.retool.com/docs/setup-instructions"
        rel="noopener noreferrer"
        target="_blank"
      >
        Full setup docs <LinkOutlined className="blue ml4" />
      </a>
      <a
        className="gray link fs-13 flex items-center"
        href="https://docs.retool.com/docs/on-premise-deployment"
        rel="noopener noreferrer"
        target="_blank"
      >
        Network and storage requirements <LinkOutlined className="blue ml4" />
      </a>
      <a
        className="gray link fs-13 flex items-center"
        href="https://docs.retool.com/docs/security"
        rel="noopener noreferrer"
        target="_blank"
      >
        Data security <LinkOutlined className="blue ml4" />
      </a>
    </div>
  )
}

export const OnPremSetupSidebar = ({ email }) => {
  return (
    <div className="onprem-setup-sidebar">
      <Typography className="onprem-setup-sidebar-title">
        <Title level={5}>
          <img className="mr8 mb4" src={ssopSidebarLogo} />
          Self-Hosted Portal
        </Title>
      </Typography>
      <a
        className={classNames('onprem-setup-sidebar-link', {
          'selected-onprem-setup-sidebar-link': window.location.pathname === '/',
        })}
        href="/"
      >
        How to install
      </a>
      <a
        className={classNames('onprem-setup-sidebar-link', {
          'selected-onprem-setup-sidebar-link': window.location.pathname === '/billing',
        })}
        href="/billing"
      >
        Plans and Billing
      </a>

      <div className="book-call-footer">
        <Typography>
          <Text>
            <IconChatBubble style={{ verticalAlign: 'middle', marginRight: '8px' }} />
            Need a 1:1 experience? <br />
          </Text>
          <a target="_blank" href={bookACallHref(email)} className="book-call-cta">
            Book a call <IconRightArrow style={{ verticalAlign: 'middle' }} />
          </a>
        </Typography>
      </div>
    </div>
  )
}

const RetoolEmbeddedApp = () => {
  // App lives here: https://docsdemos.retool.com/apps/058ae38c-fe9e-11ed-8ff3-9ba00f079759/Deployment%20Docs/Deployment%20Methods%20Grid
  const publicAppLink =
    'https://docsdemos.retool.com/embedded/public/d7ecb122-9de6-41d4-ae05-378ee240ccd2?_hideNav=true&embed=true'
  return (
    <div width="100%" height="100%" style={{ minHeight: '1100px' }}>
      <iframe src={publicAppLink} width="100%" height="100%" style={{ minHeight: '1100px', border: '0px' }} />
    </div>
  )
}

const OnPremSetupContent = (props) => {
  return (
    <div className="onprem-setup-content">
      <LicenseKey licenseKey={props.licenseKey}></LicenseKey>
      <div className="onprem-setup-content-container">
        <Typography style={{ marginTop: '40px' }} className="mt60">
          <Title level={3}>Get started with Retool self-hosted for free</Title>
        </Typography>
        <Typography className="mb20">
          <Text>
            Setup a trial of self-hosted Retool on a cloud service or locally to use Retool for free for 14 days. After
            your trial, you can choose to use our Free, Team, or Enterprise plans based on your team’s needs.
          </Text>
        </Typography>
        <RetoolEmbeddedApp />
      </div>
    </div>
  )
}

const OnPremSetupPage = (props) => {
  return (
    <div className="onprem-container flex fd-row">
      <OnPremSetupSidebar email={props.email} />
      <OnPremSetupContent licenseKey={props.licenseKey} />
    </div>
  )
}

export default OnPremSetupPage
