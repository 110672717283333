import React from 'react'
import PropTypes from 'prop-types'
import {MonochromeRetoolLogoFull} from './utils/logo'
import {Dropdown, Menu} from 'antd'
import './TopBar.scss'
import './utils/Dropdown.scss'
import './common.scss'
import {IconCaretDown} from './icons'

class TopBar extends React.Component {
  render() {
    const profilePhoto = <ProfilePhoto profilePhotoURL={this.props.userImage} userEmail={this.props.userEmail}/>
    const profileSlab = (
      <div className="flex items-center">
        {profilePhoto}
        <div className="ml12 truncate fw-500 light-gray">
          <div title={this.props.userEmail} className="fw-600 dark-gray">
            {this.props.userName || this.props.userEmail}
          </div>
          <span>Admin</span>
        </div>
      </div>
    )

    const menu = (
      <Menu>
        <Menu.Item className="headerized-menu-item">{profileSlab}</Menu.Item>
        <Menu.Item>
          <a rel="nofollow" data-method="edit" href="/ssop_users/edit">
            Settings
          </a>
        </Menu.Item>
        <Menu.Item>
          <a rel="nofollow" data-method="delete" href="/ssop_users/sign_out">
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )

    return (
      <div className="top-bar-container flex fd-row">
        <div
          onClick={() => {
            window.location.href = '/'
          }}
        >
          <MonochromeRetoolLogoFull className="top-bar-logo"/>
        </div>
        <div className="top-bar-dropdown">
          <Dropdown overlayStyle={{width: 200}} placement="bottomRight" trigger={['click']} overlay={menu}>
            <a className="flex items-center lighter-gray hover-lightest-gray">
              {profilePhoto}
              <IconCaretDown className="ml4"/>
            </a>
          </Dropdown>
        </div>
      </div>
    )
  }
}

const ProfilePhoto = (props) => {
  if (props.profilePhotoURL) {
    return <img className="profile-photo flex-no-shrink" src={props.profilePhotoURL}/>
  }
  return (
    <div className="profile-photo profile-photo-placeholder flex-no-shrink">
      <div>{props.userEmail ? props.userEmail[0].toUpperCase() : ''}</div>
    </div>
  )
}

TopBar.propTypes = {
  userEmail: PropTypes.string,
}

export default TopBar
