import React, { useState } from "react"
import '../common.scss'
import './HelpBubble.scss'
import './Dropdown.scss'
import { Dropdown, Menu } from "antd"
import { IconHelp } from "./icons/iconHelp"
import { IconHelpDocs } from "./icons/IconHelpDocs"
import { IconHelpCommunity } from "./icons/IconHelpCommunity"

const { Item } = Menu

const HelpBubble = (props) => {
    let [isMenuVisible, setIsMenuVisible] = useState(false)
    const handleVisibilityChange = (isVisible) => {
      setIsMenuVisible(isVisible)
    }

    const hideMenu = () => {
      setIsMenuVisible(false)
    }

    return (
        <Dropdown
        placement="topLeft"
        overlay={<HelpBubbleMenu hideMenu={hideMenu} />}
        onVisibleChange={handleVisibilityChange}
        visible={isMenuVisible}
        className="retool-dropdown"
        trigger={['click']}>
            <div className="retool-help-button">
                <IconHelp />
            </div>
        </Dropdown>
    )
}

const HelpBubbleMenu = (props) => {
    return (
        <Menu className="retool-help-dropdown retool-dropdown-menu" selectable={false}>
          <Item className="retool-dropdown-menu-item" name="documentation">
            <a
              href="https://docs.retool.com/docs"
              target="_blank"
              rel="noopener"
            >
              <IconHelpDocs className="retool-help-icon" />
              View documentation
            </a>
          </Item>
          <Item className="retool-dropdown-menu-item" name="community">
            <a
              href="https://community.retool.com/"
              target="_blank"
              rel="noopener"
            >
              <IconHelpCommunity className="retool-help-icon"/>
              Ask the community
            </a>
          </Item>
          <hr />
          <Item className="retool-dropdown-menu-item" name="changelog">
            <a
              href="https://updates.retool.com/"
              target="_blank"
              rel="noopener"
            >
              What's new?
            </a>
          </Item>
        </Menu>
    )
}

export default HelpBubble